import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import ReactWhatsapp from 'react-whatsapp';
import OrderCard from './components/OrderCard';
import EmailCard from './components/EmailCard';
import countryOptions from './components/Countries';

function App() {
  const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  };

  const [isChatboxOpen, setChatboxOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [showEmailCard, setShowEmailCard] = useState(false);
  const [display, setDisplay] = useState('block');

  const [userMessage, setUserMessage] = useState('');
  const [socket, setSocket] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sessionId, setSessionId] = useState();
  const [createNew, setCreateNew] = useState(false);
  const [newUser, setNewUser] = useState(() => {
    const cookieValue = getCookie('new_user');
    return cookieValue === 'true';
  });

  const [selectedCountry, setSelectedCountry] = useState('');
  const [messages, setMessages] = useState([]);
  const [sessionMessages, setsessionMessages] = useState([]);
  const inputRef = useRef(null);
  const [waiting, setWaiting] = useState(false);
  const [client_name, setClientName] = useState('');
  const [user_mail, setUsermail] = useState('');
  const [phone, setPhone] = useState('');
  const [messagesSuggestions, setMessagesSuggestions] = useState([]);
  const [msgSuggestionHeight, setMsgSuggestionHeight] = useState();
  const [restSuggestions, setRestSuggestions] = useState([]);
  const [choicesList, setChoicesList] = useState([]);
  const [showAdminSuggestions, setShowAdminSuggestions] = useState(false);
  const [colorCode, setColorCode] = useState('#000000');
  const [disableForm, setDisableForm] = useState(true);
  const [chatbotLogo, setChatbotLogo] = useState('');
  const [sessionCreated, setSessionCreated] = useState(false);
  const [merchantPhone, setMerchantPhone] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(true);
  const [showTypingIndicator, setShowTypingIndicator] = useState(!isSubmitted);
  const [disableChatbot, setDisableChatbot] = useState(false);
  const [disableChatbotGlobally, setDisableChatbotGlobally] = useState(false);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);


  const [refreshKey, setRefreshKey] = useState(0);  // Added state to trigger refresh

  // Re-trigger effects when sessionCreated is set to true
  const refreshApp = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleNameChange = (e) => {
    setClientName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setUsermail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  useEffect(() => {
    let company_name = getCookie('company_username');

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    const params = {
      params: {
        username: company_name,
      },
    };

    axios.get('https://jawebcrm.onrender.com/api/chatbot-details/', params, headers).then((res) => {
      setDisableForm(res.data.data.chatbot_form);
      setColorCode(res.data.data.chatbot_color);
      setChatbotLogo(res.data.data.chatbot_logo);
      setDisableChatbotGlobally(res.data.data.disable_chatbot_globally)
      if (res.data.data.phone_number_id != null) {
        setMerchantPhone(`+${res.data.data.phone_number_id}`);
      }
      if (createNew) {
        setMessages((prevMessages) => [{ role: 'assistant', content: res.data.data.chatbot_initial_msg }, ...prevMessages]);
      }
    });
    axios.get(`https://jawebcrm.onrender.com/api/chatbot-plugin-suggestions/`, params, headers).then((res) => {
      const suggestions = res.data.data;
    
      // Check if suggestions are not empty before proceeding
      if (suggestions && suggestions.length > 0) {
        setMessagesSuggestions(suggestions);
        
        let tempArr = suggestions;
        
        const firstList = tempArr.slice(0, 3);
        const secondList = tempArr.slice(3);
        
        setMessagesSuggestions(firstList);
        setRestSuggestions(secondList);
      } else {
        // If no suggestions, ensure state is properly reset if needed
        setMessagesSuggestions([]);
        setRestSuggestions([]);
      }
    }).catch((error) => {
      console.error('Error fetching suggestions:', error);
      // Handle error state if needed
      setMessagesSuggestions([]);
      setRestSuggestions([]);
    });
  }, []);

  const setCookie = (name, value, days) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${expirationDate.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  };

  const generateUniqueSessionId = () => {
    return 'session_' + Math.random().toString(36).substring(7);
  };

  useEffect(() => {
    test();
  }, [messagesSuggestions, isChatboxOpen]);

  const test = () => {
    const divheight = document.getElementById('mydiv');
    if (messagesSuggestions.length > 0 && divheight) {
      setMsgSuggestionHeight(divheight.offsetHeight);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const newUserCookie = getCookie('new_user');
    const sessionId = getCookie('session_id');
    
    if (!sessionId) {
      // This means there's no session ID, so the user is treated as a new user
      const newSessionId = generateUniqueSessionId();
      setCookie('session_id', newSessionId, 1); // Set the session_id cookie for 1 day
      setSessionId(newSessionId); // Update state with new session ID
      setNewUser(true); // Mark user as new
      setCreateNew(true); // Flag to create a new session
      setCookie('new_user', "true", 1); // Set new_user cookie as true
      localStorage.removeItem('message_history');
      setCookie('messages_session', JSON.stringify([]), 1); // Initialize an empty messages session
      refreshApp();  // Trigger refresh after creating the session

    } else if (newUserCookie === "true") {
      // If the new_user cookie is explicitly set to "true", treat as a new user
      localStorage.removeItem('message_history');

      setNewUser(true); // Mark user as new
      setCreateNew(true); // No need to create a new session, session_id exists
      const storedMessages = getMessagesFromCookies(); // Retrieve stored messages from cookies
      setMessages(storedMessages); // Update state with retrieved messages
    } else {
      // If the session ID exists and new_user is not "true", this is an existing user
      setSessionId(sessionId); // Update state with existing session ID
      setNewUser(false); // Mark user as not new
      const storedMessages = getMessagesFromCookies(); // Retrieve stored messages from cookies
      setMessages(storedMessages); // Update state with retrieved messages
      setCreateNew(false); // No need to create a new session
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [refreshKey]);


  useEffect(() => {
    // Debugging to ensure session creation happens only once
    console.log("Session ID:", sessionId);
    console.log("New User:", newUser);
    console.log("Create New:", createNew);
}, [sessionId, newUser, createNew]);

  const messagesRef = useRef(null);

  const storeMessagesInCookies = (messages) => {
    setCookie('messages_session', JSON.stringify(messages), 1);
  };

  const getMessagesFromCookies = () => {
    const messagesCookie = getCookie('messages_session');
    try {
      return messagesCookie ? JSON.parse(messagesCookie) : [];
    } catch (error) {
      console.error('Failed to parse messages from cookies:', error);
      return [];
    }
  };

  const scrollToBottom = () => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleButtonClick = async () => {
    if (!isChatboxOpen) {
      setChatboxOpen(true);
      setDisplay('block');
    } else {
      setIsClosing(true);
      setTimeout(() => {
        setDisplay('none');
        setIsClosing(false);
      }, 15000); 
      setChatboxOpen(false);
    }

    if (createNew && disableForm === true) {
      
      try {

        const bodyData = JSON.stringify({ name: 'Guest', isActive: true, user_session_id: getCookie('session_id'), company_username: getCookie('company_username'), mail: 'N/A', country: 'N/A', phone: 'N/A' });

        const response = await fetch('https://jawebcrm.onrender.com/api/chatlog-create/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: bodyData,
        });

        if (response.ok) {
          const data = await response.json();
          setCookie('new_user', 'false', 1);
          setNewUser(false);
          setCreateNew(false)
          setWaiting(false);
          setSessionCreated(true);
          localStorage.setItem('message_history', JSON.stringify(data.obj), 1);
        } else {
          console.error('Failed to fetch data from the API');
          setWaiting(false);
          setCookie('new_user', 'true', 1);

        }
      } catch (error) {
        console.error('Error occurred while fetching data:', error);
        setWaiting(false);
      }
    }

    setChatboxOpen(!isChatboxOpen);
  };


  const handleUpdateEmail = async (email) => {
    try {
      // Replace the URL with your API endpoint
      const response = await axios.post('https://jawebcrm.onrender.com/api/client/update-chatlog-name/', {
        email,
        sessionid: getCookie('session_id'),
      });
  
      if (response.status === 200) {
        console.log("HERE")
        setIsEmailSubmitted(true); // Mark as submitted in the parent component

        apiCall(email);
      }
    } catch (error) {
      console.error("Error submitting email:", error);
    }
  };
  
  const apiCall = async (userMessage) => {


    // // Prevent chatbot response duplication
    if (disableChatbot === false && disableChatbotGlobally === false) {
        setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages, { role: 'assistant', content: 'typing', isBusiness: false, typing: true }];
            storeMessagesInCookies(updatedMessages);
            return updatedMessages;
        });
    }

    try {
        const messageHistory = JSON.parse(localStorage.getItem('message_history'));

        console.log('Making API Call with message:', userMessage);

        const response = await fetch('https://jawebcrm.onrender.com/api/message-create/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                message: userMessage,
                isBusiness: true,
                session_id: getCookie('session_id'),
                organization: getCookie('company_username'),
                message_history: messageHistory,
            }),
        });

        if (response.ok) {
            const data = await response.json();
            if (data.message_history) {
                localStorage.setItem('message_history', JSON.stringify(data.message_history));

                const assistantMessage = { role: 'assistant', content: data.message, isBusiness: false };

                // Log to check for WebSocket interaction
                console.log('API Response:', data.message);

                // Prevent duplicate messages being added
                setMessages((prevMessages) => {
                    const existingMessage = prevMessages.find(msg => msg.content === data.message);
                    
                    if (!existingMessage) {
                        let updatedMessages = [...prevMessages.filter((msg) => !msg.typing), assistantMessage];

                        // Handle order card rendering logic based on message content
                        if (/enter\s?your\s?order|enter\s?order|order\s?number/i.test(data.message)) {
                            updatedMessages = [
                                ...updatedMessages,
                                { role: 'assistant', content: 'OrderCard', isBusiness: false, isOrderCard: true },
                            ];
                        }

                        if (/enter\s?your\s?email|enter\s?email|email\s?address/i.test(data.message)) {
                          updatedMessages = [
                              ...updatedMessages,
                              { role: 'assistant', content: 'EmailCard', isBusiness: false, isEmailCard: true },
                          ];
                      }

                        storeMessagesInCookies(updatedMessages);
                        return updatedMessages;
                    } else {
                        console.warn('Duplicate message prevented:', data.message);
                    }

                    return prevMessages;
                });
            }
            scrollToBottom();
        } else {
            console.error('Failed to fetch data from the API');
        }
    } catch (error) {
        console.error('Error occurred while fetching data:', error);
    }
};

  const apiCallChatlog = async (type) => {
    setWaiting(true);
    try {
      const bodyData = JSON.stringify({ name: client_name, isActive: true, user_session_id: getCookie('session_id'), company_username: getCookie('company_username'), mail: user_mail, country: selectedCountry, phone: phone });

      const response = await fetch('https://jawebcrm.onrender.com/api/chatlog-create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: bodyData,
      });

      if (response.ok) {
        const data = await response.json();
        setCookie('new_user', false, 1);
        setNewUser(!getCookie('new_user'));
        setWaiting(false);
        localStorage.setItem('hasName', true);
        localStorage.setItem('message_history', JSON.stringify(data.obj), 1);
      } else {
        console.error('Failed to fetch data from the API');
        setWaiting(false);
      }
    } catch (error) {
      console.error('Error occurred while fetching data:', error);
      setWaiting(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {

    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const newSocket = new WebSocket(`wss://jawebcrm.onrender.com/ws/chat/${getCookie('session_id')}/`);
    newSocket.onopen = () => {
      setSocket(newSocket);
    };

    newSocket.onmessage = (event) => {
      
      const data = JSON.parse(event.data);
      
      if (data.additionalData.editedMsg == true) {

        // console.log(data.message);
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.content === data.additionalData.originalMsg
              ? { ...msg, content: data.message } : msg
          )
        );
         
      } else {
             
        const message = data.message;
        const isBusiness = data.additionalData.isBusiness;
        if (data.additionalData.disableChatbot) {
          setDisableChatbot(data.additionalData.disableChatbot)
        }

        if (!isBusiness) {
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages, { role: 'assistant', content: message, isBusiness: false }];
            storeMessagesInCookies(updatedMessages);
            return updatedMessages;
          });
        }
        
      }

     
    };

    newSocket.onclose = (event) => {
      console.log('WebSocket connection closed:', event.reason);
    };

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [getCookie('session_id')]);

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      const userMessage = e.target.value;
      e.target.value = '';
      setWaiting(true);

      if (socket && socket.readyState === WebSocket.OPEN) {
        const additionalData = { isBusiness: true, SessionId: sessionId };
        const messagePayload = JSON.stringify({
          message: userMessage,
          additionalData: additionalData,
        });
        socket.send(messagePayload);
      }

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, { role: 'user', content: userMessage, isBusiness: true }];
        storeMessagesInCookies(updatedMessages);
        return updatedMessages;
      });

      scrollToBottom();
      apiCall(userMessage);
      setUserMessage('');
    }
  };

  const handleButtonPress = async () => {
    if (waiting) return; // Prevent double submission
    setWaiting(true);

    if (socket && socket.readyState === WebSocket.OPEN) {
      const additionalData = { isBusiness: true, SessionId: sessionId };
      const messagePayload = JSON.stringify({
        message: userMessage,
        additionalData: additionalData,
      });
      socket.send(messagePayload);
    }

    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, { role: 'user', content: userMessage, isBusiness: true }];
      storeMessagesInCookies(updatedMessages);
      return updatedMessages;
    });

    scrollToBottom();
    apiCall(userMessage);
    setUserMessage('');
  };

  const parseMessage = (message) => {
    const imageRegex = /\bhttps?:\/\/[^\s]+(?:png|jpg|jpeg|gif|svg)[^\s]*/gi;
    const urlRegex = /\b[a-zA-Z0-9-]+\.myshopify\.com\/\S*/gi;

    const combinedRegex = new RegExp(`${imageRegex.source}|${urlRegex.source}`, 'gi');

    const parts = message.split(combinedRegex);
    const matches = message.match(combinedRegex) || [];
    let matchIndex = 0;

    return parts.map((part, index) => {
      if (matchIndex < matches.length && message.includes(matches[matchIndex])) {
        const match = matches[matchIndex];
        if (match.match(imageRegex)) {
          matchIndex++;
          return (
            <img
              key={`img-${index}`}
              src={match.replace(')', '')}
              alt="Image"
              style={{ maxWidth: '100%', borderRadius: '5px', marginTop: '10px' }}
            />
          );
        }

        if (match.match(urlRegex)) {
          const properUrl = match.match(/^https?:\/\//) ? match : `http://${match}`;
          matchIndex++;
          return (
            <a
              key={`link-${index}`}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                window.open(properUrl.replace(')', ''), '_blank');
              }}
              style={{ textDecoration: 'underline' }}
            >
              (Link)
            </a>
          );
        }
      }
      return <span key={`text-${index}`}>{part} </span>;
    });
  };

  const handleSubmit = async (type) => {
    apiCallChatlog(type);
  };

  const onSuggestedMsgClick = (question) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const additionalData = { isBusiness: true, SessionId: sessionId };
      const messagePayload = JSON.stringify({
        message: question,
        additionalData: additionalData,
      });
      socket.send(messagePayload);
    }

    setMessagesSuggestions(messagesSuggestions.filter((item) => item !== question));
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', content: question, isBusiness: true },
    ]);
    apiCall(question);

    if (messagesSuggestions.length < 4 && restSuggestions.length !== 0) {
      let newSuggestion = restSuggestions.pop();
      setMessagesSuggestions((prevMsg) => [...prevMsg, newSuggestion]);
    }
  };

  const onAdminMsgSuggestion = (question) => {
    setShowAdminSuggestions(false);

    if (socket && socket.readyState === WebSocket.OPEN) {
      const additionalData = { isBusiness: true, SessionId: sessionId };
      const messagePayload = JSON.stringify({
        message: question,
        additionalData: additionalData,
      });
      socket.send(messagePayload);
    }

    setMessagesSuggestions(messagesSuggestions.filter((item) => item !== question));
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', content: question, isBusiness: true },
    ]);
    apiCall(question);

    if (messagesSuggestions.length < 4 && restSuggestions.length !== 0) {
      let newSuggestion = restSuggestions.pop();
      setMessagesSuggestions((prevMsg) => [...prevMsg, newSuggestion]);
    }
  };
  

  return (
    <div>
      <div style={{ position: 'fixed', bottom: 10, right: 10, zIndex: 999 }}>
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <button
            style={{
              borderRadius: 30,
              background: colorCode,
              padding: 10,
              transition: 'box-shadow 0.3s ease',
              boxShadow: '0 0 0 0 rgba(127, 46, 248, 0)',
            }}
            onClick={handleButtonClick}
            onMouseOver={(e) => (e.currentTarget.style.boxShadow = `0 0 10px 5px ${colorCode}`)}
            onMouseOut={(e) => (e.currentTarget.style.boxShadow = '0 0 0 0 rgba(127, 46, 248, 0)')}
          >
            {isChatboxOpen ? (
              <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.601 8.39897C18.269 8.06702 17.7309 8.06702 17.3989 8.39897L12 13.7979L6.60099 8.39897C6.26904 8.06702 5.73086 8.06702 5.39891 8.39897C5.06696 8.73091 5.06696 9.2691 5.39891 9.60105L11.3989 15.601C11.7309 15.933 12.269 15.933 12.601 15.601L18.601 9.60105C18.9329 9.2691 18.9329 8.73091 18.601 8.39897Z"
                  fill="white"
                ></path>
              </svg>
            ) : (
              <img
                src="https://static.ada.support/images/e1f9c0f5-51ce-45e4-8240-41d357ef3ed8.svg"
                alt="Chatbot"
              />
            )}
          </button>
        </div>

        {(isChatboxOpen) ? (
          <div
            id="chatbox-container"
            style={{
              position: 'fixed',
              bottom: windowWidth < 768 ? '10%' : '0',
              right: windowWidth < 768 ? 0 : 10,
              top: windowWidth < 768 ? '15%' : '0' ,
              // marginBottom: '5px',
              paddingInline: 10,
              paddingTop: 10,
              height: windowWidth < 768 ? '75%' : '90%',
              borderRadius: 10,
              width: windowWidth < 768 ? '100%' : '27%',
              zIndex: 999,
              animation: isChatboxOpen ? 'slideIn 0.7s forwards' : isClosing ? 'slideOut 15s forwards' : 'none',
            }}
          >
            <style>
              {`
                @keyframes slideIn {
                  0% {
                    transform: translateY(100%);
                  }
                  100% {
                    transform: translateY(0);
                  }
                }

                @keyframes slideOut {
                  0% {
                    transform: translateY(0);
                  }
                  100% {
                    transform: translateY(120%);
                  }
                }
              `}
            </style>
            {newUser && !disableForm ? (
              <div>
                <div
                  style={{
                    background: '#ECF0F1',
                    width: '100%',
                    height: '100%',
                    borderRadius: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: 10,
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    {waiting ? (
                      <div
                        style={{
                          marginTop: 10,
                          marginBottom: 10,
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <span className="typing-indicator-dot"></span>
                        <span className="typing-indicator-dot"></span>
                        <span className="typing-indicator-dot"></span>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div>
                    <p style={{ fontWeight: '400', fontSize: 26 }}>👋</p>
                  </div>
                </div>

                <div>
                  <form style={{ display: 'block', maxWidth: '300px', margin: '0 auto' }}>
                    <div className="relative w-full min-w-[200px] h-10 mt-9">
                      <input
                        className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-purple-500"
                        placeholder=" "
                        onChange={handleNameChange}
                      />
                      <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-blue-gray-400 peer-focus:text-purple-500 before:border-blue-gray-200 peer-focus:before:!border-purple-500 after:border-blue-gray-200 peer-focus:after:!border-purple-500">
                        Name
                      </label>
                    </div>

                    <div className="relative w-full min-w-[200px] h-10 mt-9">
                      <input
                        className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-purple-500"
                        placeholder=" "
                        onChange={handleEmailChange}
                      />
                      <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-blue-gray-400 peer-focus:text-purple-500 before:border-blue-gray-200 peer-focus:before:!border-purple-500 after:border-blue-gray-200 peer-focus:after:!border-purple-500">
                        Email
                      </label>
                    </div>

                    <div className="relative w-full min-w-[200px] h-10 mt-9">
                      <input
                        className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-purple-500"
                        placeholder=""
                        onChange={handlePhoneChange}
                        onFocus={(e) => (e.target.placeholder = 'e.g., +1234567890')}
                        onBlur={(e) => (e.target.placeholder = '')}
                      />
                      <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-blue-gray-400 peer-focus:text-purple-500 before:border-blue-gray-200 peer-focus:before:!border-purple-500 after:border-blue-gray-200 peer-focus:after:!border-purple-500">
                        Phone
                      </label>
                    </div>

                    <div className="relative w-full min-w-[200px] h-10 mt-9">
                      <select
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2  text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-purple-500"
                      >
                        <option value="" disabled hidden className="mb-6">
                          Select a country
                        </option>
                        {countryOptions.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <button
                        type="button"
                        onClick={() => handleSubmit('user')}
                        style={{
                          backgroundColor: colorCode,
                          color: 'white',
                          padding: '10px',
                          borderRadius: '5px',
                          border: 'none',
                          cursor: 'pointer',
                          width: '100%',
                          marginTop: '40px',
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <div
                style={{
                  position: 'fixed',
                  bottom: windowWidth < 768 ? '20%' : '0',
                  right: windowWidth < 768 ? 0 : 10,
                  background: 'white',
                  paddingInline: 10,
                  paddingTop: 10,
                  height: windowWidth < '20%' ? 0 : '95%',
                  borderRadius: 10,
                  border: `1px solid ${colorCode}`,
                  width: windowWidth < 768 ? '100%' : '100%',
                  zIndex: 999,
                  animation: isChatboxOpen ? 'slideIn 0.3s forwards' : 'slideOut 0.3s forwards',
                }}
              >
                <div
                  style={{
                    background: colorCode,
                    width: '100%',
                    height: '80px',
                    borderRadius: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: 10,
                    justifyContent: 'space-between',
                    border: '1px solid #e1e1e5',
                  }}
                >
                  <div>
                    <img
                      style={{
                        border: '1px solid #7f2ef8',
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                      }}
                      src={
                        chatbotLogo
                          ? chatbotLogo
                          : 'https://www.shutterstock.com/image-vector/chat-bot-logo-design-concept-600nw-1938811039.jpg'
                      }
                      alt="Logo"
                    />
                  </div>
                  {merchantPhone && (
                    <ReactWhatsapp
                      style={{
                        backgroundImage: 'url("https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png")',
                        backgroundSize: 'cover',
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                      }}
                      number={merchantPhone}
                      message="Would you like to connect?"
                    />
                  )}
                </div>

                <div
                  style={{
                    height: 'calc(85% - 100px)',
                    overflowY: 'scroll',
                    padding: 10,
                  }}
                  ref={messagesRef}
                >
                  {messages.map((message, index) => (
                    <div key={index} style={{ marginBottom: 10 }}>
                      {message.isBusiness ? (
                        <div
                          style={{
                            padding: 8,
                            borderRadius: 5,
                            background: colorCode,
                            color: 'white',
                            width: '100%',
                          }}
                        >
                          <p style={{ margin: 0, wordWrap: 'break-word', fontSize: '13px' }}>{parseMessage(message.content)}</p>
                        </div>
                      ) : message.typing ? (
                        <div
                          style={{
                            background: 'transparent',
                            color: 'black',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <span className="typing-indicator-dot"></span>
                          <span className="typing-indicator-dot"></span>
                          <span className="typing-indicator-dot"></span>
                        </div>
                      ) : message.isOrderCard ? (
                        <OrderCard color={colorCode} isSubmitted={isSubmitted} setIsSubmitted={setIsSubmitted} />
                      ) : message.isEmailCard ? (
                        <EmailCard 
                        color={colorCode}
                        onSubmit={handleUpdateEmail} 
                        isEmailSubmitted={isEmailSubmitted} 
                        setIsEmailSubmitted={setIsEmailSubmitted} 
                      />
                      ): (
                        <div
                          style={{
                            padding: 8,
                            borderRadius: 5,
                            background: '#f0f0f0',
                            color: 'black',
                            width: '100%',
                          }}
                        >
                          <p style={{ margin: 0, wordWrap: 'break-word', fontSize: '13px' }}>{parseMessage(message.content)}</p>
                        </div>
                      )}
                    </div>
                  ))}
                  <ul className="scroll-wrapper-option mb-2 mt-2" style={{ borderColor: colorCode }}>
                    {showAdminSuggestions &&
                      choicesList.map((value, index) => (
                        <li key={index} className="sub_wrapper" onClick={() => onAdminMsgSuggestion(value)}>
                          {value}
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="test">
                <div className="scroll-wrapper" id="mydiv" style={{ marginBottom: '4px', overflowX: 'auto' }}>
                  <div 
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap:'4px',
                      height: messagesSuggestions.length === 0 ? '0px' : 'auto',
                      padding: '0',
                      scrollbarWidth: 'thin',
                      msOverflowStyle: 'auto',
                    }}
                  >
                    {messagesSuggestions.length > 0 &&
                      messagesSuggestions.map((question, id) => (
                        <div
                          key={id}
                          style={{
                            padding: '6px 24px',
                            border: `1px solid ${colorCode}`, // Dynamic border color
                            borderRadius: '8px',
                            textAlign: 'center',
                            flexShrink: 0, // Prevents flex items from shrinking
                          }}
                        >
                          <p style={{ fontSize: '12px', cursor: 'pointer',paddingBottom:'0px' }} onClick={() => onSuggestedMsgClick(question)}>
                            {question}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <div style={{ width: '100%' }}>
                      <input
                        ref={inputRef}
                        type="text"
                        style={{
                          width: '100%',
                          border: `1px solid ${colorCode}`,
                          background: '#f3f3f3',
                          padding: 10,
                          color: '#444',
                          borderRadius: 15,
                          fontSize: 16,
                          lineHeight: 2,
                        }}
                        onChange={(e) => {
                          setUserMessage(e.target.value);
                        }}
                        value={userMessage}
                        placeholder="Send Message ..."
                        onKeyDown={handleKeyPress}
                      />
                    </div>
                    <div onClick={handleButtonPress} style={{ marginLeft: '-30px', background: '#f3f3f3' }} className="flex items-center">
                      <button type="button" style={{ pointerEvents: 'none' }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          className="_svg_1cum9_1"
                        >
                          <path
                            d="M19.5656 12.5913C19.4092 12.9268 19.1426 13.1929 18.8233 13.3529L6.75036 19.4461C5.89387 19.8649 4.86032 19.5233 4.42563 18.6844C4.2343 18.2793 4.19549 17.822 4.32795 17.3955L5.64831 13.0972C5.7692 12.7037 6.13274 12.435 6.54448 12.435H11.1654C11.505 12.4337 11.7828 12.1592 11.7905 11.8101C11.7884 11.4663 11.514 11.1885 11.1654 11.1851H6.54788C6.13452 11.1851 5.76995 10.9144 5.65042 10.5187L4.34749 6.20514C4.07489 5.3055 4.58948 4.34594 5.50008 4.07643C5.92157 3.94393 6.37753 3.98415 6.76989 4.19362L18.8233 10.2868C19.6653 10.7168 20.0041 11.7517 19.5656 12.5913Z"
                            fill={colorCode}
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="mb-4 flex items-center justify-center">
                    <a className="text-gray-400" href="https://jaweb.me/" target="_blank" rel="noopener noreferrer">
                      Powered By Jaweb
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        ):null}
      </div>
    </div>
  );
}

export default App;
