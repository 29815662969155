import React, { useState, useEffect } from 'react';
import { Input, Button, Form, message, Space, Steps } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Step } = Steps;

const OrderCard = ({ color, isSubmitted, setIsSubmitted }) => {
  const [orderNumber, setOrderNumber] = useState("");
  const [Loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");

  const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  };

  const fetchOrderStatus = async (orderNumber) => {
    try {
      console.log(getCookie('company_username'))
      setLoading(true);
      // Replace the URL with your API endpoint
      const response = await axios.get(`https://jawebcrm.onrender.com/api/order-status?identifier=${orderNumber}&shopUrl=${getCookie('company_username')}`);

      if (response.status === 200) {
        const status = response.data; // Adjust according to your API response
        console.log(status.order_status)
        setOrderStatus(status.order_status);
        message.success("Order status fetched successfully!");
      }
    } catch (error) {
      message.error("Failed to fetch order status.");
      console.error("Error fetching order status:", error);
      setOrderStatus("");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (orderNumber) {
      fetchOrderStatus(orderNumber);
    } else {
      message.error("Please enter an order number or Email");
    }
  };

  return (
    <Form layout="vertical" style={{ background: '#F6F7F7', padding: '20px', borderRadius: '2px', marginBottom: '2px' }}>
      <Space.Compact style={{ width: '100%' }}>
        <Input
          style={{ width: '100%' }}
          placeholder="Enter your email or Order#"
          value={orderNumber}
          type='text'
          onChange={(e) => setOrderNumber(e.target.value)}
          prefix={<MailOutlined />}
          required
        />
        <Button loading={Loading} onClick={handleSubmit} style={{ background: color, color: 'white' }}   >Submit</Button>
      </Space.Compact>
      
      {orderStatus && (
        <Steps current={orderStatus === "Confirmed" ? 0 : orderStatus === "Shipped" ? 2 : 0} style={{ marginTop: '20px' }}>
          <Step title="Confirmed" />
          <Step title="Shipped" />
        </Steps>
      )}
    </Form>
  );
};

export default OrderCard;
